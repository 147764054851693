import PropTypes from "prop-types";
import React from "react";

const VimeoPlayer = ({ title, vimeoId }) => {
  const url = `//player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0`;
  return (
    <iframe src={url} title={title} width="600" height="337" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
    </iframe>
  );
};

VimeoPlayer.propTypes = {
  title: PropTypes.string,
  vimeoId: PropTypes.string,
};

VimeoPlayer.defaultProps = {};

export default VimeoPlayer;
