import React from "react";

import Social from "./Social";
import "./Intro.css";

const Intro = () => {
  return (
    <div className="intro">
      <h2>
        Soma Holiday is a freelance software engineer, LED artist, creative
        coder, and educator.
      </h2>
      <p>
        I also cook, dance, write, and occasionally refer to myself in third
        person.
      </p>
      <p>
        I'm motivated by magic: the magic of a new ability learned, of a new
        skill exercised, of a new connection formed.
      </p>
      <p>Drop me a line. Let's make magic.</p>
      <Social />
    </div>
  );
};

export default Intro;
