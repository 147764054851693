import React from "react";

// Prevent flash of huge icons https://github.com/FortAwesome/react-fontawesome/issues/234
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagramSquare,
  faGithubSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";

import "./Social.css";

// Prevent flash of huge icons https://github.com/FortAwesome/react-fontawesome/issues/234
config.autoAddCss = false;

const Social = () => {
  const services = [
    {
      href: "mailto:soma@somaholiday.com",
      icon: faEnvelopeSquare,
    },
    {
      href: "https://www.instagram.com/somahol/",
      icon: faInstagramSquare,
    },
    {
      href: "https://github.com/somaholiday/",
      icon: faGithubSquare,
    },
    {
      href: "https://twitter.com/somahol",
      icon: faTwitterSquare,
    },
  ];

  return (
    <div className="social">
      {services.map(({ href, icon }) => (
        <a href={href} key={href}>
          <FontAwesomeIcon icon={icon} size="2x" />
        </a>
      ))}
    </div>
  );
};

export default Social;
