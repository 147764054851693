import PropTypes from "prop-types";
import React from "react";

import ProjectListItem from "./ProjectListItem";
import "./ProjectList.css";

const ProjectList = ({ projects }) => (
  <div className="legacy-projectList">
    {projects.map((project) => (
      <ProjectListItem key={project.title} project={project} />
    ))}
  </div>
);

ProjectList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
};

ProjectList.defaultProps = {};

export default ProjectList;
