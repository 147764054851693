import React, { useEffect, useRef } from "react";
import SceneManager from "../threejs/SceneManager";
import "./Tetrahedron.css";

function init(mountRef) {
  const canvas = createCanvas(document, mountRef);
  const sceneManager = new SceneManager(canvas);

  bindEventListeners();
  render();

  function createCanvas(document, mountRef) {
    const canvas = document.createElement("canvas");
    mountRef.appendChild(canvas);
    return canvas;
  }

  function bindEventListeners() {
    window.onresize = resizeCanvas;
    resizeCanvas();
  }

  function resizeCanvas() {
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    sceneManager.onWindowResize();
  }

  function render(time) {
    requestAnimationFrame(render);
    sceneManager.update();
  }
}

function Tetrahedron() {
  const mountRef = useRef(null);
  useEffect(() => {
    init(mountRef.current);
  }, []);
  return <div className="tetrahedron" ref={mountRef}></div>;
}

export default Tetrahedron;
