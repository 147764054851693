import * as THREE from "three";

function Tetrahedron(scene) {
  let rot_current = 0;
  let rot_vel_max = 0.003;
  let rot_vel = 0;
  let rot_acc = 0;

  const mesh = createMesh();

  scene.add(mesh);

  // slide mesh slightly up to fit in frame
  mesh.translateY(0.07);

  const TWO_PI = 2 * Math.PI;
  let started = false;

  function update(time) {
    const rot_y = rot_current % TWO_PI;
    const rot_x = 0; //(rot_current / 3.0) % TWO_PI;

    mesh.rotation.x = -rot_x;
    mesh.rotation.y = -rot_y;

    if (time > 5 && !started) {
      rot_acc = 0.00001;
      started = true;
    } else if (started && rot_vel >= rot_vel_max) {
      rot_acc = 0;
    }
    rot_current += rot_vel;
    rot_vel += rot_acc;
  }

  return { update, mesh };
}

function createMesh() {
  const origin = typeof window !== "undefined" ? window.location.origin : "";
  const host = `${origin}/tetrahedron/`;
  const loader = new THREE.TextureLoader();
  const texture = loader.load(`${host}textures.png`);

  const geometry = new THREE.TetrahedronGeometry();

  geometry.faceVertexUvs[0] = [
    [
      new THREE.Vector2(0.25, 0),
      new THREE.Vector2(0.5, 0.5),
      new THREE.Vector2(0, 0.5),
    ],
    [
      new THREE.Vector2(0.75, 0),
      new THREE.Vector2(1, 0.5),
      new THREE.Vector2(0.5, 0.5),
    ],
    [
      new THREE.Vector2(0.25, 0.5),
      new THREE.Vector2(0, 1),
      new THREE.Vector2(0.5, 1),
    ],
    [
      new THREE.Vector2(0.75, 0.5),
      new THREE.Vector2(0.5, 1),
      new THREE.Vector2(1, 1),
    ],
  ];

  const material = new THREE.MeshBasicMaterial({
    map: texture,
  });

  const mesh = new THREE.Mesh(geometry, material);

  return mesh;
}

export default Tetrahedron;
