import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";

import Tetrahedron from "./Tetrahedron";

// https://medium.com/@soffritti.pierfrancesco/how-to-organize-the-structure-of-a-three-js-project-77649f58fa3f

function SceneManager(canvas) {
  const clock = new THREE.Clock();

  const screenDimensions = {
    width: canvas.width,
    height: canvas.height,
  };

  const scene = buildScene();
  const renderer = buildRenderer(screenDimensions);
  const camera = buildCamera(screenDimensions);
  const controls = buildControls(canvas);
  const sceneSubjects = createSceneSubjects(scene);

  function buildScene() {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#000");

    return scene;
  }

  function buildRenderer({ width, height }) {
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true,
    });
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
    renderer.setPixelRatio(DPR);
    renderer.setSize(width, height);

    return renderer;
  }

  function buildCamera({ width, height }) {
    const aspectRatio = width / height;
    const fieldOfView = 45;
    const nearPlane = 0.1;
    const farPlane = 5;
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearPlane,
      farPlane
    );

    camera.position.z = 3;

    return camera;
  }

  function buildControls(canvas) {
    return buildTrackballControls(canvas);
  }

  function buildTrackballControls(canvas) {
    const controls = new TrackballControls(camera, canvas);

    controls.keys = [];
    controls.noPan = true;
    controls.noZoom = true;

    controls.dynamicDampingFactor = 0.05;
    controls.rotateSpeed = 1.8;

    return controls;
  }

  function createSceneSubjects(scene) {
    const tetrahedron = new Tetrahedron(scene);

    // move the camera to point directly at this face
    const normal = tetrahedron.mesh.geometry.faces[1].normal;
    const camDistance = camera.position.length();
    camera.position.copy(normal).multiplyScalar(camDistance);

    const sceneSubjects = [tetrahedron];

    return sceneSubjects;
  }

  function update() {
    const elapsedTime = clock.getElapsedTime();

    controls.update();

    for (let i = 0; i < sceneSubjects.length; i++) {
      sceneSubjects[i].update(elapsedTime);
    }

    renderer.render(scene, camera);
  }

  function onWindowResize() {
    const { width, height } = canvas;

    screenDimensions.width = width;
    screenDimensions.height = height;

    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    renderer.setSize(width, height);
  }

  return {
    update,
    onWindowResize,
  };
}

export default SceneManager;
