import React from "react";

import "./Heading.css";

const Heading = () => (
  <div className="heading">
    <h1>
      Soma Ho<span className="l">l</span>
      <span className="i">i</span>
      <span className="d">d</span>ay
    </h1>
  </div>
);

export default Heading;
