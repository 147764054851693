import PropTypes from "prop-types";
import React from "react";

import VimeoPlayer from "./VimeoPlayer";
import { toLower, kebab } from "../../utils/strings";
import "./ProjectListItem.css";

const ProjectListItem = ({ project }) => {
  const { title, subtitle, description, vimeoId } = project;

  return (
    <div className="legacy-projectListItem" id={kebab(toLower(title))}>
      <h2>{title}</h2>
      <div className="legacy-projectListItem-subtitle">{subtitle}</div>

      <VimeoPlayer vimeoId={vimeoId} title={title} />

      <p dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

ProjectListItem.propTypes = {
  project: PropTypes.object,
};

ProjectListItem.defaultProps = {};

export default ProjectListItem;
