import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Tetrahedron from "../components/Tetrahedron";

import Heading from "../components/legacy/Heading";
import Projects from "../components/legacy/Projects";

import Intro from "../components/Intro";

export default () => {
  return (
    <Layout>
      <SEO title="Home" />

      <Tetrahedron />
      <Heading />
      <Intro />
      <Projects />
    </Layout>
  );
};
